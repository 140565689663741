import { useAuthStore } from '~/store/auth.js'

export default defineNuxtRouteMiddleware(async (to) => {
  const token = to.params.token
  const email = to.query.email

  if (!token || !email) {
    ElMessage.error('Missing required parameters')
    return navigateTo('/login')
  }

  try {
    await useAuthStore().checkResetPasswordToken(token, email)
  } catch (e) {
    ElMessage.error('Token is invalid or expired')
    return navigateTo('/login')
  }
})
